import {
  ChevronDownIcon,
  FileTextIcon,
  GearIcon,
  ListBulletIcon,
  PlusIcon,
} from '@radix-ui/react-icons';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import logo from 'assets/headerLogo.svg';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { fetchProjects, selectProjects } from 'store/project';
import { logout, selectProfile } from 'store/user';
import { UserRole } from 'types';

import DropdownMenu from '../common/DropdownMenu';

import styles from './Header.module.scss';

// @TODO: it is just mocked img, there shouldn't be icon from library
// import { PersonIcon } from '@radix-ui/react-icons';

const userImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAMFBMVEXU1NT////Y2Nj7+/va2trm5ubz8/Pf39/29vbe3t7j4+P8/Pzt7e3Z2dn09PTp6enlgXfuAAAEj0lEQVR4nO2dCZarOgxEMVPCkGT/u31N8+mEEIIHVUmf47sC6ghNRhZFkclkMplMJpPJZDKZTCaTyWQymUwmk8lsKLuu75sf+r7rSu2niaNrxrZyK6p2bDrt5wqibtrB7TC0Ta39fH6Uj+ueiIXrw/5r1rdHKmbaXvtJv9JUxxL+PKbRfto9yhAZsxSTb1gfKONXir0XrPb0jXdaYyHssRtujxge2s/+wu0w4H7jetN+/oU+2hz/GcWIp4xpMiZGbQ0TkV6+ptVWUZR3CR3O3ZVTSpnk5q9cVZWUEUlwj0pRiZw9JhRtIuQfC3ctHSLx6hWl2PWQ1uGcSrlykdfh3IWvQzJgPVEIXeIOMkN3kwajwzlyA1wmFrz7DNyXS6Di3YNaCXc4Hc4xDyNFS5N3rjwdPVKHc7yGEWoQokkgOf0VVn4HG4RmEmjImuEELmAOWeDkEki1uKZi6ADH3hlGBAaVvWsYRTCsXHxlwOuAJ5EZfCoBdOqfwHfv8Gw4A8+JJUeHc+j+iuQieCeB9ervoHt3Qn0yg65SKOlwAp0SCYXWDLrcYulwDquDFn3R8bfmCcGORBC6wwVsl3gaIbTEjk7tlPZwBtsknsYip/GR0wg5TR45TYlynqKR1LLjm/bT9COk0yD8edBpDh9OcxzEClv4DwukYxT8px5S/Yv/QEJyEsJECiUlMr7rUg5NGcNOlHeLMutEqFI4c3SEuEUaq4HnRMpn9oLg7qy5RtxA4wxvrBFcy/PmsTHDywvMIWaol1Anf4F1CnE2s4Ae1JGv7sPaEvZNPpS/868r1JBkMijcQYaUXCqXXQFuonTVVTwGcyPvE2mH17tS2Yk6/KC4/KWTvOKqusSmFlNSKS9/kFKiraMobiJKKgN7HySuUOteZv8jOTOaWPkwcUl6vSqFC7p7lAmHdq2N12ohdjeKlZ0oT25RnjIaiFYbuuDwdbW6ke4S5CqtISff0Hi7ymB24VlR9mNQGK7G3lbA+qVsonaL3I1tb/PdBfgJO/sB67A3aks1qpe+P1xE1tXctSPYRW6bk6aUXnYJkpazyFnjT4qGVW6Qr9QtvfaKX8z4HfLaxph1n74Q14KmtFE+sFqttMbWB07zSxmhwx9H1KxLx+CqJXVtqT/YZp42vjwBDMS0i7ozKEeRXS/pA+YkVe4Lgj+IM3oNHQglOjrklWjpkFYi+a0wWIngcaSePX6ViNkEOzDnoUQoCvPzxztC+YR2P2wfkclscl3yGYFqhbbR5TvJZ/fEW8bfSQzC2gHrSWLoMuDoC0kOb8RBZhLcBDOAGUvC4KZ6JlwTPSlI7dB9iOzibb1YE5Evl6GItRAVuYi7XPyJOOyykwpfiUiLJmrFLcHVI/pCWCzBF8mMGiTYJFYNEmwSswYJNMnNrEF+TBLy4dewQYJMYtdDJgK8xFy1uMa/djSZ1J943xInLpqLw/frtcGyd41nEUzcVxqLn7sbd/UJP3c31ql/wqt7Jy7+i8en5zV1lrWHzxmX8E8OMXj8OvF/ELMmjuOWyTOHLcenEOaz4cxxTjRd+D7Z/KDkH+MbT03dnEr6AAAAAElFTkSuQmCC';

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useAppSelector(selectProfile);
  const handleLogout = () => dispatch(logout());
  const projects = useAppSelector(selectProjects);
  const currentProject = projects.find((project) => project.id === params.id);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const projectTitles =
    projects.map((project) => ({
      title: project.title,
      action: () => {
        navigate(`/project/${project.id}/quests`);
        window.location.reload();
      },
    })) || [];
  const projectDropdownItems = [
    projects.length > 0 ? { label: 'Account' } : null,
    ...projectTitles,
    undefined,
    { label: 'Actions' },
    profile?.role === UserRole.OWNER
      ? {
          title: 'Create new project',
          right: <PlusIcon />,
          action: () => navigate('/projects/create'),
        }
      : null,
    { title: 'Projects list', right: <ListBulletIcon />, action: () => navigate('/projects') },
  ].filter((i) => i !== null);

  return (
    <div className={styles.header_container}>
      <div className={styles.header_left}>
        <DropdownMenu items={projectDropdownItems}>
          <div className={styles.projects_dropdown}>
            <img className={styles.projects_dropdown__logo} src={logo}></img>
            {currentProject ? (
              <div className={styles.projects_dropdown__info}>
                <div className={styles.projects_dropdown__name}>
                  <span>{currentProject?.title}</span>
                  <ChevronDownIcon />
                </div>
                <div className={styles.projects_dropdown__id}>
                  ID {currentProject?.xsollaProjectId}
                </div>
              </div>
            ) : (
              <div className={styles.projects_dropdown__info}>
                <div className={styles.projects_dropdown__name}>
                  <span>Projects</span>
                  <ChevronDownIcon />
                </div>
              </div>
            )}
          </div>
        </DropdownMenu>
      </div>

      <div className={styles.header_right}>
        <a target='_blank' href={process.env.REACT_APP_DOCS_HOST} rel='noreferrer'>
          <div
            className={classNames(
              styles.header_right_item_content,
              styles.header_right_item_margin,
            )}
          >
            <FileTextIcon className='icon-color' />
            <div className={styles.header_right_item_text}>Documentation</div>
          </div>
        </a>

        {profile?.role === UserRole.OWNER ? (
          <Link to='/manage-company'>
            <div
              className={classNames(
                styles.header_right_item_content,
                styles.header_right_item_margin,
              )}
            >
              <GearIcon className='icon-color' />
              <div className={styles.header_right_item_text}>Manage company</div>
            </div>
          </Link>
        ) : null}

        <DropdownMenu
          // TODO: move to constants
          items={[
            // { title: 'Profile settings', link: '/my-profile' },
            { title: 'Log out', action: handleLogout },
          ]}
        >
          <div className={classNames(styles.header_right_item, styles.header_right_item_margin)}>
            <img src={userImg} className={styles.header_right_user_icon}></img>
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Header;
