import * as Dropdown from '@radix-ui/react-dropdown-menu';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import styles from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  items: (
    | {
        title: string;
        link?: string;
        action?: () => void;
        styles?: object;
        label?: string;
        right?: React.ReactNode | string;
      }
    | undefined
  )[];
  children?: React.ReactNode;
  onOpenChange?: Dispatch<SetStateAction<boolean>>;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ children, onOpenChange, items = [] }) => {
  return (
    <Dropdown.Root onOpenChange={onOpenChange}>
      <Dropdown.Trigger className={styles.DropdownMenuTrigger} asChild>
        {children}
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content className={styles.DropdownMenuContent} sideOffset={5}>
          {items.map((item, index) => {
            if (!item) {
              return <Dropdown.Separator key={index} className={styles.DropdownMenuSeparator} />;
            }

            if (item.label) {
              return (
                <Dropdown.Label key={index} className={styles.DropdownMenuLabel}>
                  {item.label}
                </Dropdown.Label>
              );
            }

            return (
              <Dropdown.Item
                onClick={item.action ? item.action : () => {}}
                key={item.title + index}
                className={styles.DropdownMenuItem}
                style={item.styles}
              >
                {item.link ? <Link to={item.link}>{item.title}</Link> : <span>{item.title}</span>}
                {item.right ? <div className={styles.RightSlot}>{item.right}</div> : null}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
export default DropdownMenu;
